import {
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from "../market";

const WILDBERRIES_FAQ = [
  {
    title: "Что такое поведенческий фактор (ПФ) на Wildberries",
    isH3: true,
    text: (
      <p>
        Поведенческий фактор (ПФ) на Wildberries - это один из алгоритмов,
        используемых платформой Wildberries для определения рейтинга товаров на
        сайте.
        <br />
        ПФ учитывает различные параметры, связанные с поведением покупателей,
        чтобы предсказать потенциальную популярность и продажи товара.
        <br />
        <br />
        Поведенческий фактор учитывает количество просмотров товара, добавления
        в избранное,
        <br />
        добавления в корзину и совершение покупки. Он также может учитывать
        отзывы и оценки покупателей, чтобы оценить качество и популярность
        товара.
        <br />
        <br />
        ПФ применяется для ранжирования и сортировки товаров на Wildberries,
        чтобы показать наиболее релевантные и популярные товары покупателям.
        <br />
        Чем выше ПФ, тем вероятнее что товар будет показан в верхних результатах
        поиска и привлечет больше внимания покупателей.
        <br />
        <br />
        Улучшая поведенческий фактор на Wildberries через Boostclick — вы
        увеличиваете вероятность повышения ваших позиций в поисковой выдачи
        маркетплейса.
      </p>
    ),
  },
  {
    title: "Как работают алгоритмы органического продвижения на Wildberries?",
    isH3: true,
    text:
      <div>
        <ul>
          <li>
            Релевантность: Алгоритмы учитывают, насколько товар соответствует
            запросу пользователя.
            <br />
            Это включает в себя ключевые слова, описания, заголовки, а также
            атрибуты товара, такие как бренд, цвет, размер и т.д.
          </li>
          <br />
          <li>
            Продажи: Популярность и уровень продаж товара также влияют на его
            рейтинг. Алгоритмы оценивают объемы продаж, количество отзывов и
            оценки покупателей.
          </li>
          <br />
          <li>
            Качество листинга: Товары с более полной и информативной
            страницей листинга, включая высококачественные изображения,
            <br />
            подробное описание, характеристики и т.д., могут иметь более высокий
            рейтинг.
          </li>
          <br />
          <li>
            Уровень доверия к продавцу: Оценки и отзывы покупателей, а также
            время на платформе Вайлдберриз и количество успешных сделок влияют
            <br />
            на доверие к продавцу и могут повлиять на ранжирование его товаров.
          </li>
          <br />
          <li>
            Цены и скидки: Цены на товары и наличие скидок также могут
            сыграть роль в алгоритмах органического продвижения.
            <br />
            Более конкурентоспособные цены и привлекательные предложения могут
            повысить рейтинг товаров.
          </li>
          <br />
        </ul>
        Boostclick может влиять на CTR карточки товара, на конверсию добавления
        товара в корзину, на продолжительность сессии на карточки товара.
        Данные действия положительно скажутся на выдаче товара только в
        совокупности соблюдения первых 5 пунктов.
      </div>
  },
  {
    title: "Гарантии и безопасность",
    isH3: true,
    text:
      <p>
        Накрутка поведенческого фактора является вспомогательной и
        дополнительной мерой в рекламных кампаниях.
        <br />
        Мы гарантируем, что исполнители Boostclick реальные люди, которые
        получают точные инструкции для безопасной накрутки ПФ.
        <br />
        <br />
        Для соблюдения органичного поиска, исполнители не получают URL ссылки на
        продвигаемые страницы, что гарантирует органический поиск.
        <br />
        Мы запрещаем исполнителям делать скриншоты, что исключает фиксирование
        Яндекс Метрикой и Вебвизором сам факт скриншота.
        <br />
        Для проверки мы используем различные микрозадачи, которые можно
        выполнить только находясь на продвигаемой странице.
      </p>
    },
  {
    title: "Как оплатить улучшение поведенческого фактора?",
    isH3: true,
    text:
      <p>
        Пополнить личный кабинет Boostclick можно любым удобным способом через
        сервис Робокасса. Минимальная оплата для физических
        лиц{'\u00A0—\u00A090\u00A0'} рублей.
        <br />
        Минимальная сумма пополнения для юридических
        лиц{'\u00A0—\u00A010000\u00A0'}рублей.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = (
  <p>
    Почему при добавлении одного действия в калькуляторе появляются
    дополнительные действия?
    <br />
    <br />
    Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
    сценарий начинается для нового исполнителя с поиска товара по сделанному
    скриншоту вашей ссылки.
  </p>
);

const REVIEW_PRICE = 250;

const ACTIONS = [
  {
    type: "MAIN.FAVORITE",
    icon: "heart",
    iconHome: "home-heart",
    title: "Добавить товар в избранное",
    initialShare: 0.6,
    activityType: 'Избранное',
  },
  {
    type: "MAIN.BUSKET",
    icon: "busket",
    iconHome: "home-busket",
    title: "Добавить товар в корзину",
    initialShare: 0.4,
    activityType: 'Корзина',
  },

  {
    type: "ALWAYS.OPEN",
    icon: "open",
    iconHome: "home-open",
    title: "Открыть сайт или приложение WB",
    initialShare: 1,
  },
  {
    type: "ALWAYS.SEARCH",
    icon: "search",
    iconHome: "home-search",
    title: "Найти товар через поиск WB",
    initialShare: 1,
  },
  {
    type: 'ALWAYS.COMPETITORS',
    icon: "time",
    iconHome: "home-time",
    title: 'Быстро посмотреть конкурентов',
    initialShare: 1,
  },
  {
    type: "ALWAYS.CLICK",
    icon: "click",
    iconHome: "home-click",
    title: "Зайти на карточку товара",
    initialShare: 1.0,
  },

  {
    type: "EXTRA.MEDIA",
    icon: "eye",
    iconHome: "home-eye",
    title: "Посмотреть все фото и видео на карточке",
    initialShare: 0.6,
  },
  {
    type: "EXTRA.DESCRIPTION",
    icon: "glasses",
    iconHome: "home-glasses",
    title: "Прочитать полное описание товара",
    initialShare: 0.2,
  },
  {
    type: "EXTRA.GOODS",
    icon: "click",
    iconHome: "home-click",
    title: "Посмотреть другие товары продавца",
    initialShare: 0.1,
  },
  {
    type: "EXTRA.FEEDBACKS",
    icon: "glasses",
    iconHome: "home-glasses",
    title: "Прочитать отзывы на товар",
    initialShare: 0.1,
  },

  {
    type: "MAIN.LIKE",
    icon: "like",
    iconHome: "home-like",
    title: "Поставить положительную реакцию на отзыв",
    initialShare: 0,
    activityType: 'Лайк',
  },
  {
    type: "MAIN.DISLIKE",
    icon: "dislike",
    iconHome: "home-dislike",
    title: "Поставить отрицательную реакцию на отзыв",
    initialShare: 0,
    activityType: 'Дизлайк',
  },
  {
    type: "MAIN.APPEAL",
    icon: "dislike",
    iconHome: "home-dislike",
    title: "Пожаловаться на последний отзыв",
    initialShare: 0,
    activityType: 'Жалоба',
  },
];

const STEP_PHRASE_2 = "Укажите информацию для поиска товара";

const WILDBERRIES_KEYWORDS = [
  'Wildberries', 'wildberries', 'Вайлдберриз', 'вайлдберриз', 'вб', 'wb'
];

class WildberriesClass extends Market {
  id = "wildberries";
  host = "wildberries.ru";
  hostsList = ["wildberries.ru", "wb.ru"];
  url = "https://wildberries.ru/";

  title = "Wildberries";

  isMarketplace = true;

  icon = "wildberries";
  titlePlaces = "Wildberries";
  statusForHomePage = MARKET_MODE_STATUS.POPULAR;

  categories = [
    { type: "Женщинам" },
    { type: "Обувь" },
    { type: "Детям" },
    { type: "Мужчинам" },
    { type: "Дом" },
    { type: "Красота" },
    { type: "Аксессуары" },
    { type: "Электроника" },
    { type: "Игрушки" },
    { type: "Мебель" },
    { type: "Продукты" },
    { type: "Цветы" },
    { type: "Бытовая техника" },
    { type: "Зоотовары" },
    { type: "Спорт" },
    { type: "Автотовары" },
    { type: "Транспортные средства" },
    { type: "Школа" },
    { type: "Книги" },
    { type: "Ювелирные изделия" },
    { type: "Для ремонта" },
    { type: "Сад и дача" },
    { type: "Здоровье" },
    { type: "Канцтовары" },
    { type: "Культурный код" },
    { type: "Цифровые товары" },
    { type: "Путешествия" },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = 24;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = WILDBERRIES_KEYWORDS;

  targetLinkComment = "Укажите тут адрес вашего товара на Wildberries";
  targetLinkCommentShort = "Укажите ссылку товара";

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: "/povedencheskie-faktory-na-wildberries",
      rating: 0.8,
      title: "ПФ",
      navigateTitle: "Улучшение ПФ на Wildberries",
      icon: "lightning",
      iconForHomePage: "home-action",
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title:
          "Накрутка поведенческих факторов (ПФ) Wildberries - купить за 1.9 руб просмотры, отзывы, лайки | Boostclick",
        description: "Улучшайте ПФ на Wildberries с помощью сервиса Boostclick. С нами вы можете заказать поведенческий фактор.",
      },
      intro: {
        title: "Накрутка поведенческих факторов Wildberries",
        comment: 'Благодаря индивидуальным поведенческим сценариям пользователей',
        points: [
          "Без ботов",
          "Без самовыкупов",
          "Только уникальные пользователи",
          "Соблюдение сроков",
          "Подробные отчеты",
          "Поиск вашего магазина Вайлдберриз по ключевым запросам",
        ],
      },
      howItWorks: [
        "Настройте параметры активности в соответствии с конкуренцией",
        "Укажите ссылку на товар для скриншота",
        "Укажите категорию, город и ключевые фразы из карточки товара",
        "После оплаты мы передадим исполнителю скриншот и параметры для поиска",
        "Проверьте ответы и активность, примите работу или отправьте на доработку",
      ],
      stepPhrase1: "Настройте поведенческий фактор для карточки Wildberries ",
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: "Продвижение на Wildberries",
      titleFAQ: "Вопрос-ответ по накрутке трафика на Вайлдберриз",
      faq: WILDBERRIES_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(
            (action) =>
              action.type !== "MAIN.REVIEW" && !action.type.startsWith("VIEW.")
          ),
          ...ACTIONS.filter((action) => action.type === "MAIN.REVIEW"),
        ];
      })(),
      notice: [
        "* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.",
        "Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на странице вашего товара.",
        "В первую очередь будут выполняться действия связанные с органическим поиском и изучением товаров, далее добавление в избранное и корзину.",
        "Сложность цепочек и время исполнения зависит от их количества в проекте.",
      ],
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action;
    return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на товар';
  invalidUrlStr = 'Укажите корректную ссылку на товар';
}

const Wildberries = new WildberriesClass();
export default Wildberries;
