import { observer } from "mobx-react";
import { useEffect } from "react";

import { SiteLinks } from "./SiteLinks/SiteLinks";
import { SiteIcons } from "./SiteIcons/SiteIcons";

import dropDownMenuStore from "../DropDownMenu/DropDownMenuStore";
import AppStore from "AppStore";

import "./DropDownLinks.scss";

export const DropdownLinks = observer(
  ({ isOpen, onClose, links, isMobile, headerForDesktop }) => {
    useEffect(() => {
      dropDownMenuStore.setDisplayFormat(isMobile ? "row" : "grid");
    }, [isMobile]);

    const dropdownLinksStyles = {
      position: "fixed",
      top: isMobile && !headerForDesktop ? "0" : "64px",
      left: "0",
      right: "0",
      bottom: "0",
      transform: isOpen ? "translateX(0)" : "translateX(-130%)",
      zIndex: "9",
      width: "100%",
      height: isMobile ? "calc(100% - 66px)" : "100vh",
      display: "flex",
      opacity: isOpen ? "1" : "0",
      visibility: isOpen ? "visible" : "hidden",
      transition: "all 0.6s",
      overflowY: "auto",
    };

    const handleLinkClick = (link) => {
      if (link.onClick) link.onClick();
      onClose();
    };

    return (
      <div className="dropdown-links" style={dropdownLinksStyles} onClick={() => onClose()}>
        <div className="dropdown-links__blur" />
        <div className="dropdown-links__container" onClick={(e) => e.stopPropagation()}>
          <div className="dropdown-links__wrapper">
            <div className="dropdown-links__wrapper-items">
              {(!isMobile || (isMobile && dropDownMenuStore.displayFormat === "row")) && <SiteLinks links={links} onClick={handleLinkClick} />}
              <SiteIcons storeDropDown={dropDownMenuStore} storeApp={AppStore} isMobile={isMobile} onClick={handleLinkClick} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
