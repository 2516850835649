import {
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from '../market';

const REVIEW_PRICE = 350;
const WARRANTY_HOURS = 168;

const CHAIN_PRICE = 24;
const CHAIN_ACTION_PRICE = 3.5;

export const YANDEXMAPS_FAQ = [
  {
    title: 'Как работает биржа Boostclick по написанию отзывов для Яндекс Карт?',
    isH3: true,
    text: (
      <div>
        Вы создаете задание для исполнителей, где указываете качества, которые должны быть отражены в отзыве.
        Можно также предоставить примеры или фото для отзыва (ссылка на Яндекс Диск приветствуется).
        <br />
        <br />
        После написания отзыва на Яндекс Картах исполнитель предоставляет вам отчет с текстом отзыва и датой размещения.
        <br />
        <br />
        Если отзыв прошел модерацию Яндекса и выполнен верно, вы принимаете работу. Если же отзыв не прошел модерацию
        или выполнен неверно, вы можете либо заменить исполнителя, либо отправить задание на доработку.
      </div>
    ),
  },
  {
    title: 'Как долго размещаются отзывы на Яндекс Картах?',
    isH3: false,
    text: (
      <div>
        Исполнитель имеет 24 часа на написание отзыва после получения задания. После этого отзыв проходит модерацию
        Яндекса, которая может занять до 68 часов. В итоге весь процесс от создания проекта до размещения отзыва занимает
        от 3 до 7 дней.
      </div>
    ),
  },
  {
    title: 'Какие гарантии на отзывы на Яндекс Картах?',
    isH3: false,
    text: (
      <div>
        Boostclick использует систему модерации, чтобы гарантировать качество работы. У вас есть до {WARRANTY_HOURS} часов на проверку
        задания.
        <br />
        <br />
        Важно знать, что 90% некачественных или не прошедших модерацию отзывов удаляются в первые 68 часов.
      </div>
    ),
  },
  {
    title: 'Как вы подбираете авторов отзывов?',
    isH3: false,
    text: (
      <div>
        Мы сотрудничаем с авторами, которые специализируются на написании отзывов для Яндекс Карт от начального уровня
        до знатоков города 20-го уровня.
      </div>
    ),
  },
  {
    title: 'Вы работаете с юридическими лицами?',
    isH3: false,
    text: (
      <div>
        Да, мы работаем с юридическими лицами. <br />
        <br />
        Вы можете сформировать счет в личном кабинете. Акты мы отправим вам через ЭДО.
      </div>
    ),
  },

  {
    title: 'Вы работаете официально с физическими лицами?',
    isH3: false,
    text: (
      <div>
        Да, мы работаем официально с физическими лицами. <br />
        <br />
        Оплату можно провести через Робокассу, оплатив любым удобным способом. После оплаты вы получите чек на указанную вами почту.
      </div>
    ),
  },

];

const STEP_TWO_TITLE_FAQ = (
  <p>
    Почему при добавлении одного действия в калькуляторе появляются
    дополнительные действия?
    <br />
    <br />
    Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
    сценарий начинается для нового исполнителя с поиска организации / места по сделанному
    скриншоту вашей ссылки.
  </p>
);

const ACTIONS = [
  {
    type: 'MAIN.REVIEW',
    icon: "message", 
    iconHome: "home-reviews",
    title: 'Написать отзыв о компании',
    initialShare: 0,
    price: REVIEW_PRICE,
    itemTitle: 'отзыв',
    activityType: 'Отзыв', // NOTE: do not change (see PopupStore.js abd ReportModal.jsx)
  },

  { type: 'ALWAYS.OPEN', icon: "open", iconHome: "home-open", title: 'Открыть Яндекс Карты / Поиск Яндекс', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: "search", iconHome: "home-search", title: 'Написать поисковый запрос', initialShare: 1 },
  { type: 'ALWAYS.FIND', icon: "search", iconHome: "home-search", title: 'Найти профиль', initialShare: 1 },
  { type: "ALWAYS.FEEDBACKS", icon: "glasses", iconHome: "home-glasses", title: "Прочитать отзывы", initialShare: 1 },

  {
    type: 'EXTRA.LIKE',
    icon: "like", 
    iconHome: "home-like",
    title: 'Поставить «Полезно» на последний положительный отзыв',
    initialShare: 0,
    activityType: 'Лайк',
  },

  {
    type: 'EXTRA.DISLIKE',
    icon: "dislike", 
    iconHome: "home-dislike",
    title: 'Поставить «Бесполезно» на последний отрицательный отзыв',
    initialShare: 0,
    activityType: 'Дизлайк',
  },

  { type: 'EXTRA.MEDIA', icon: "glasses", iconHome: "home-glasses", title: 'Просмотреть все фото и видео', initialShare: 0.5 },
  { type: "EXTRA.DESCRIPTION", icon: "glasses", iconHome: "home-glasses", title: "Прочитать полное описание", initialShare: 0.5 },

  {
    type: 'MAIN.CLICK',
    icon: "click",
    iconHome: "home-click",
    title: 'Перейти на сайт компании',
    initialShare: 0.2,
    price: CHAIN_ACTION_PRICE,
    activityType: 'Переход',
  },

  {
    type: 'MAIN.ABOUT',
    icon: "glasses",
    iconHome: "home-glasses",
    title: 'Просмотреть «Особенности»',
    initialShare: 0.1,
    price: CHAIN_ACTION_PRICE,
    activityType: 'Инфо',
  },

  {
    type: 'MAIN.ROUTE',
    icon: "click",
    iconHome: "home-click",
    title: 'Построить маршрут до места / клик «Маршрут»',
    initialShare: 0.2,
    price: CHAIN_ACTION_PRICE,
    activityType: 'Маршрут',
  },

  {
    type: 'MAIN.PHONE',
    icon: "click",
    iconHome: "home-click",
    title: 'Нажать «Показать номер»',
    initialShare: 0.2,
    price: CHAIN_ACTION_PRICE,
    activityType: 'Телефон',
  },

  {
    type: 'MAIN.FAVORITE',
    icon: "heart",
    iconHome: "home-heart",
    title: 'Сохранить компанию в избранное',
    initialShare: 0.3,
    price: CHAIN_ACTION_PRICE,
    activityType: 'Избранное',
  },
];

const STEP_PHRASE_2 = 'Укажите информацию для поиска компании на Яндекс Картах';

const YANDEXMAPS_KEYWORDS = [
  'Яндекс Карты', 'Yandex Maps', 'Yandex Карты', 'Яндекс Maps', 'Я.Карты'
];

class YandexMapsClass extends Market {

  id = 'yandexmaps';
  host = 'yandex.ru';
  hostsList = [
    'yandex.ru',
    'yandex.org',
    'yandex.net',
    'yandex.net.ru',
    'yandex.com.ru',
    'yandex.by',
    'yandex.eu',
    'yandex.ee',
    'yandex.lt',
    'yandex.lv',
    'yandex.md',
    'yandex.uz',
    'yandex.tm',
    'yandex.de',
    'yandex.nu',
    'yandex.tj',
    'yandex.az',
    'ya.ru',
    'yandex.com',
    'yandex.asia',
    'yandex.mobi',
    'maps.yandex.ru'
  ];
  url = 'https://yandex.ru/maps/';

  title = 'Яндекс Карты';

  isMarketplace = false;
  isMaps = true;

  icon = "yandexmap";
  titlePlaces = 'Я.Карты';
  statusForHomePage = MARKET_MODE_STATUS.NEW;

  categories = [
    { "type": "Еда" },
    { "type": "Продукты" },
    { "type": "Медицина" },
    { "type": "Магазины" },
    { "type": "Авто" },
    { "type": "Красота" },
    { "type": "Стройка и ремонт" },
    { "type": "Государство" },
    { "type": "Почта" },
    { "type": "Банки и финансы" },
    { "type": "Услуги" },
    { "type": "Транспорт" },
    { "type": "Развлечения и отдых" },
    { "type": "Туризм" },
    { "type": "Образование" },
    { "type": "Спорт" },
    { "type": "Домашние животные" },
    { "type": "Прочее" },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = CHAIN_PRICE;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = YANDEXMAPS_KEYWORDS;

  targetLinkComment = 'Укажите тут ссылку вашей компании на Яндекс Картах';
  targetLinkCommentShort = 'Укажите ссылку компании';

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: '/povedencheskie-faktory-na-yandexmaps',
      rating: 0.9689,
      title: 'ПФ Яндекс Карты',
      navigateTitle: 'Заказать ПФ на Яндекс Картах',
      icon: "lightning",
      iconForHomePage: "home-action",
      status: MARKET_MODE_STATUS.REGULAR,
      statusForHomePage: MARKET_MODE_STATUS.NEW,
      meta: {
        title: `Накрутка ПФ на Яндекс Картах от ${CHAIN_PRICE} рублей — заказать накрутку ПФ Яндекс Карт людьми | Boostclick`,
        description: `Улучшайте ПФ на Яндекс Картах с помощью сервиса Boostclick. С сервисом вы можете заказать накрутку ПФ от ${CHAIN_PRICE
          } р. Задания выполняют реальные люди.`,
      },
      intro: {
        title: 'Накрутка поведенческих факторов на Яндекс Картах',
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Увеличит просмотры и активность',
          'Оптимизирует расходы на рекламу',
          'Улучшит позиции в поиске',
          'Увеличит шансы на попадание в рекомендации и рассылки на досках объявлений'
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствии с конкуренцией',
        'Укажите ссылку на карточку компании для скриншота',
        'Укажите категорию, город и ключевые фразы из карточки компании',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте стоимость ПФ на Яндекс Картах и параметры',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Заказать ПФ на Яндекс Картах',
      titleFAQ: 'Вопрос-ответ по накрутке ПФ на Яндекс Картах',
      faq: YANDEXMAPS_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
          ...ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        '** При написании отзывов исполнители полностью проходят путь пользователя от органического поиска до просмотра услуг / контактов.',
        'Отзывы будут оставлены исполнителем в промежутке от 1 до 5 дней после просмотра информации о компании',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на карточке вашей компании.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением информации о месте, далее просмотр отзывов и услуг. В последнюю очередь делаются действия с контактными данными.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ]
    },
    {
      id: MARKET_MODES.REVIEWS,
      path: '/reviews-on-yandexmaps',
      rating: 0.969,
      title: 'Отзывы',
      navigateTitle: 'Заказать отзывы на Яндекс Картах',
      icon: "message",
      iconForHomePage: "home-reviews",
      status: MARKET_MODE_STATUS.REGULAR,
      statusForHomePage: MARKET_MODE_STATUS.NEW,
      meta: {
        title: `Купить отзывы на Яндекс Картах от ${REVIEW_PRICE} рублей — заказать накрутку отзывов Яндекс Карт людьми | Boostclick`,
        description: `Улучшайте отзывы на Яндекс Картах с помощью сервиса Boostclick. С сервисом вы можете заказать накрутку отзывов от ${REVIEW_PRICE
          } р. Задания выполняют реальные люди. Нужна помощь по сервису? Пишите в поддержу.`,
      },
      intro: {
        title: `Купить отзывы на Яндекс Картах`,
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Гарантия на отзывы',
          'Официально, чек после оплаты',
          'Переписка с исполнителем или звонок в чате для лучшей модерации при накрутке отзыва на Яндекс Картах',
          'Оплата исполнителю только после публикации отзыва до 168 часов',
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствии с конкуренцией',
        'Укажите ссылку на карточку компании для скриншота',
        'Укажите категорию, город и ключевые фразы из карточки компании',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте стоимость отзывов на Яндекс Картах и параметры',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Заказать отзывы на Яндекс Картах',
      titleFAQ: "Вопрос-ответ по накрутке отзывов на Яндекс Картах",
      faq: YANDEXMAPS_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
          ...ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        '** При написании отзывов исполнители полностью проходят путь пользователя от органического поиска до просмотра услуг / контактов.',
        'Отзывы будут оставлены исполнителем в промежутке от 1 до 5 дней после просмотра информации о компании',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на карточке вашей компании.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением информации о месте, далее просмотр отзывов и услуг. В последнюю очередь делаются действия с контактными данными.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ]
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action; return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку вашей компании на картах';
  invalidUrlStr = 'Укажите корректную ссылку на вашу компанию';
}

const YandexMaps = new YandexMapsClass();
export default YandexMaps;
