import { useLocation } from "react-router-dom";
import { FooterLink } from "./FooterLink/FooterLink";
import FooterWrapper from "./FooterWrapper/FooterWrapper";
import FooterPlaceLogos from "./FooterPlaceLogos/FooterPlaceLogos";

import qrCodeNews from "../assets/images/footer/qr-code-news.svg";
import qrCodeSupport from "../assets/images/footer/qr-code-support.svg";
import vc from "../assets/images/footer/vc.svg";

import { EVENTS, metricsEvent } from "metrics";

import "./Footer.scss";

export default function Footer() {
  const { pathname } = useLocation();
  const headerForDesktop = pathname === "/projects" || pathname.startsWith("/blog");

  return (
    <footer className="footer">
      <div className="footer__wrapper">
          <FooterPlaceLogos />
          <FooterWrapper
            className="footer__wrapper-support"
            title="Возникли сложности?"
            subtitle="За помощью сюда!"
            mobileSubtitle="Техническая поддержка"
            icon={qrCodeSupport}
            titleButton="Написать в Telegram"
            iconButton="telegram-icon-white"
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
          />
          <FooterWrapper
            className="footer__wrapper-news"
            title="Обновления, новости и отзывы на сервис в канале"
            mobileSubtitle="Информация для вас"
            icon={qrCodeNews}
            titleButton="Канал в Telegram"
            iconButton="telegram-icon-white"
            to={process.env.REACT_APP_NEWS_ADDRESS}
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_CHANNEL) }}
          />
          <FooterWrapper
            className="footer__wrapper-vc"
            title="О нас пишут на vc.ru"
            subtitle={["Отзывы и обзор", <br />, "на сервис BOOSTCLICK"]}
            icon={vc}
            titleButton="Подробнее на vc.ru"
            to={"https://vc.ru/aggo/1027111-nakrutka-povedencheskih-faktorov-na-avito-s-boostclick"}
          />
      </div>
      <div className={"footer__links-container" + (!headerForDesktop ? " visible-menu" : "")}>
        <FooterLink text="Договор оферты" to="/docs/offer.pdf" />
        <FooterLink text="Условия оказания услуг" to="/docs/rules.pdf" />
        <FooterLink text="Политика конфиденциальности" to="/docs/policy.pdf" />
      </div>
    </footer>
  );
}
