import { Fragment } from "react";
import { observer } from "mobx-react";

import Icon from "components/UI/IconSVG/Icon";

import AppStore from "AppStore";

import "./ProjectsActiveReportChains.scss";

export const ProjectsActiveReportChains = observer(({ project, report }) => {
    const chain = report.chain;
    const status = report.status;
    const market = AppStore.marketsMap[project.type.toLowerCase()];

    return (
      <div className={`projects-active-report-row__container ${ status === "finished" ? "opacity" : "" }`}>
        <div className="projects-active-report-row__chains">
          {chain.map((action, i) => (
            <Fragment key={i}>
              <div className={`projects-active-report-row__chains-item ${action.startsWith("MAIN") ? "with-border" : ""}`}>
                <Icon className="projects-active-report-row__chains-item-img" key={i} sprite="activities" name={market.actionsMap[action].icon} color="#FFE14B" />
                {market.actionsMap[action].title}
              </div>
              {i !== chain.length - 1 && <div className="projects-active-report-row__chains-dash" />}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
);
