import {
  action, computed, makeObservable, observable, runInAction
} from 'mobx';

import messageBoxStore from 'MessageBox/MessageBoxStore';
import popupStore from 'components/Popup/PopupStore';

import client, { ClientError } from 'client';

/**
 * @typedef BonusesHistoryEntry
 * @property {number} id
 * @property {Date} datetime
 * @property {number} amount
 * @property {string} status
 * @property {boolean} self Вызвана ли операция самостоятельным действием.
 * Можно показать свой email.
 * @property {string} [emailHidden] Email для показа, если не self.
 */

/**
 * @typedef BonusesStatsEntry
 * @property {number} id
 * @property {string} emailHidden
 * @property {Date} datetime
 * @property {number} amount
 */

export const WithdrawalMethods = {
  ACCOUNT: 'account',
  CARD: 'card',
};

const DEFAULT_APP_HOST_ADDRESS = 'https://boostclick.ru';
const PAGINATION_SIZE = 10;

/**
 * @param {number} userId
 * @returns {string}
 */
function buildReferralLink(userId) {
  const host = process.env.REACT_APP_HOST_ADDRESS ?? DEFAULT_APP_HOST_ADDRESS;
  const url = new URL(`/invite/${userId}`, host);
  return url.toString();
}

/**
 * @typedef {object} Pagination
 * @property {number} pages
 * @property {number} curr
 */

/** Стор для кабинета рефки. */
class ReferralStore {

  /** @type {?string} */
  referralLink = undefined;

  /** @type {?number} */
  bonusesAvailable = undefined;
  /** @type {?number} */
  bonusesEarned = undefined;
  /** @type {?number} */
  bonusesWithdrawing = undefined;
  /** @type {?number} */
  bonusesWithdrawn = undefined;

  /** @type {BonusesHistoryEntry[]|undefined} */
  bonusesHistory = undefined;
  /** @type {Pagination|undefined} */
  bonusesHistoryPagination = undefined;
  bonusesStats = undefined;
  /** @type {?Pagination} */
  bonusesStatsPagination = undefined;

  /** @type {string} */
  withdrawalMethod = WithdrawalMethods.ACCOUNT;

  /** @type {?number} */
  amountToWithdraw = undefined;

  /** @type {boolean} */
  agreedWithOffer = false;

  /** @type {boolean} */
  doWithdrawalRunning = false;

  startDate = null;
  endDate = null;

  #clearBonuses() {
    this.bonusesAvailable = undefined;
    this.bonusesEarned = undefined;
    this.bonusesWithdrawing = undefined;
    this.bonusesWithdrawn = undefined;
  }

  /** @param {import('client').BonusesInfo} info */
  #parseBonuses(info) {
    this.bonusesAvailable = info.available;
    this.bonusesEarned = info.earned;
    this.bonusesWithdrawing = info.withdrawing;
    this.bonusesWithdrawn = info.withdrawn;
  }

  constructor() {
    makeObservable(this, {
      fetchData: action,
      referralLink: observable,

      bonusesAvailable: observable,
      bonusesEarned: observable,
      bonusesWithdrawing: observable,
      bonusesWithdrawn: observable,

      bonusesHistory: observable,
      bonusesHistoryPagination: observable,
      bonusesStats: observable,
      bonusesStatsPagination: observable,

      fetchBonusesHistory: action,
      fetchBonusesStats: action,

      withdrawalMethod: observable,
      minWithdrawalAmount: computed,
      setWithdrawalMethod: action,

      amountToWithdraw: observable,
      amountToReceive: computed,
      setAmountToWithdraw: action,

      agreedWithOffer: observable,
      setAgreedWithOffer: action,

      resetWithdrawalParams: action,
      canWithdraw: computed,
      doWithdrawal: action,
      doWithdrawalRunning: observable,

      startDate: observable,
      endDate: observable,
      setStartDate: action,
      setEndDate: action,
    });
  }

  /** Загрузка всех данных, нужных для отрисовки кабинета рефки. */
  fetchData() {
    this.#fetchProfile();
    this.#fetchBonusesInfo();
    this.#fetchBonusesHistory(1, true, true);
    this.#fetchBonusesStats(1, true, true);
  }

  fetchBonusesHistory(page, clear = true) {
    this.#fetchBonusesHistory(page, clear);
  }

  fetchBonusesStats(page, clear = true) {
    this.#fetchBonusesStats(page, clear);
  }

  #fetchingProfile = false;
  /** Загрузка данных профиля для получения идентификатора пользователя. */
  async #fetchProfile() {
    if (this.#fetchingProfile)
      return;
    this.#fetchingProfile = true;
    this.referralLink = undefined;
    try {
      const profile = await client.getProfile();
      const link = buildReferralLink(profile.id);
      runInAction(() => {
        this.referralLink = link;
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingProfile = false;
    }
  }

  #bonusesReferrals = new Map();

  #fetchingBonusesInfo = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesInfo() {
    if (this.#fetchingBonusesInfo)
      return;
    this.#fetchingBonusesInfo = true;

    this.#clearBonuses();
    try {
      const info = await client.getBonusesInfo();
      runInAction(() => {
        this.#parseBonuses(info);
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      this.#fetchingBonusesInfo = false;
    }
  }

  #fetchingBonusesHistory = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesHistory(page = 1, clear = true, clearImmediately = false) {
    if (this.#fetchingBonusesHistory)
      return;
    this.#fetchingBonusesHistory = true;
    try {
      await this.#fetchBonusesHistoryHelper(page, clear, clearImmediately);
    } finally {
      this.#fetchingBonusesHistory = false;
    }
  }
  async #fetchBonusesHistoryHelper(page, clear, clearImmediately) {
    if (clear && clearImmediately) {
      this.bonusesHistory = undefined;
      this.bonusesHistoryPagination = undefined;
    }

    let res;
    try {
      res = await client.getBonusesHistory({
        offset: PAGINATION_SIZE * (page - 1),
        limit: PAGINATION_SIZE,
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
      return;
    }
    const { total, referrals, entries } = res;

    for (const [referralId, referral] of Object.entries(referrals))
      this.#bonusesReferrals.set(Number(referralId), referral);

    const rem = total % PAGINATION_SIZE;
    const pages = (total - rem) / PAGINATION_SIZE + (rem ? 1 : 0);
    const curr = Math.min(page, pages);

    const makeEntry = (entry) => {
      const { type, status } = entry;
      const self = !entry.referralId;

      /** @type {BonusesHistoryEntry} */
      const t = {
        id: entry.id,
        datetime: new Date(entry.datetime),
        amount: entry.amount,
        self,
        status: (() => {
          if (type === 'INCOME') {
            if (status === 'finished')
              return 'Начислено';
            if (status === 'processing')
              return 'Начисление в процессе';
            return 'Начисление';
          }
          if (type === 'WITHDRAWAL') {
            if (status === 'finished')
              return 'Выведено на счет';
            if (status === 'processing')
              return 'Вывод в процессе';
            return 'Вывод';
          }
          return type;
        })(),
      };

      if (!self) {
        const referral = this.#bonusesReferrals.get(entry.referralId);
        t.emailHidden = referral.emailHidden;
      }

      return t;
    }

    let newHistory;
    if (clear) {
      newHistory = entries.map(entry => makeEntry(entry));
    } else {
      const newEntries = [];
      for (const entry of entries) {
        if (!this.bonusesHistory.some(e => e.id === entry.id))
          newEntries.push(makeEntry(entry));
      }
      newHistory = this.bonusesHistory?.concat(newEntries) ?? newEntries;
    }

    runInAction(() => {
      this.bonusesHistory = newHistory;
      this.bonusesHistoryPagination = { pages, curr };
    });
  }

  #fetchingBonusesStats = false;
  /** Загрузка данных о бонусах. */
  async #fetchBonusesStats(page, clear = true, clearImmediately = false) {
    if (this.#fetchingBonusesStats)
      return;
    this.#fetchingBonusesStats = true;
    try {
      await this.#fetchBonusesStatsHelper(page, clear, clearImmediately);
    } finally {
      this.#fetchingBonusesStats = false;
    }
  }
  async #fetchBonusesStatsHelper(page, clear, clearImmediately) {
    if (clear && clearImmediately) {
      this.bonusesStats = undefined;
      this.bonusesStatsPagination = undefined;
    }

    let res;
    try {
      res = await client.getBonusesStats({
        offset: PAGINATION_SIZE * (page - 1),
        limit: PAGINATION_SIZE,
      });
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
      return;
    }
    const { total, entries } = res;

    const rem = total % PAGINATION_SIZE;
    const pages = (total - rem) / PAGINATION_SIZE + (rem ? 1 : 0);
    const curr = Math.min(page, pages);

    const makeEntry = (entry) => {
      const r = entry.referral;
      return {
        id: r.id,
        emailHidden: r.emailHidden,
        datetime: r.registerDatetime ? new Date(r.registerDatetime) : null,
        amount: entry.earned,
      }
    };

    let newStats;
    if (clear) {
      newStats = entries.map(entry => makeEntry(entry));
    } else {
      const newEntries = [];
      for (const entry of entries) {
        if (!this.bonusesStats.some(e => e.id === entry.id))
          newEntries.push(makeEntry(entry));
      }
      newStats = this.bonusesStats?.concat(newEntries) ?? newEntries;
    }

    runInAction(() => {
      this.bonusesStats = newStats;
      this.bonusesStatsPagination = { pages, curr };
    });
  }

  /** @param {string} method */
  setWithdrawalMethod(method) {
    this.withdrawalMethod = method;
  }

  /** @param {number|string} */
  setAmountToWithdraw(value) {
    if (value === undefined)
      return;

    if (value === null) {
      this.amountToWithdraw = undefined;
      return;
    }

    if (typeof value === 'string') {
      if (!value.trim()) {
        this.amountToWithdraw = undefined;
        return;
      }
      value = Number(value);
    }
    if (typeof value !== 'number')
      return;

    if (!Number.isFinite(value))
      return;
    if (value < 0 || value > 1000000)
      return;
    if (!Number.isInteger(value) && !Number.isInteger(value * 100))
      return;

    this.amountToWithdraw = value;
  }

  /** @returns {?number} */
  get amountToReceive() {
    if (!this.canWithdraw)
      return undefined;
    if (!this.amountToWithdraw)
      return 0;
    if (this.withdrawalMethod === WithdrawalMethods.ACCOUNT)
      return this.amountToWithdraw;
    if (this.withdrawalMethod === WithdrawalMethods.CARD)
      return Math.floor((this.amountToWithdraw * 0.94) * 100) / 100;
    return 0;
  }

  /** @returns {boolean} */
  get canWithdraw() {
    if (this.withdrawalMethod === WithdrawalMethods.CARD) {
      if (!this.agreedWithOffer)
        return false;
    }

    const amount = this.amountToWithdraw;
    return amount && this.bonusesAvailable
      && amount >= this.minWithdrawalAmount
      && amount <= this.bonusesAvailable;
  }

  get minWithdrawalAmount() {
    switch (this.withdrawalMethod) {
      case WithdrawalMethods.ACCOUNT:
        return 100;
      case WithdrawalMethods.CARD:
        return 50000;
      default:
        return 0;
    }
  }

  setAgreedWithOffer(value) {
    this.agreedWithOffer = !!value;
  }

  resetWithdrawalParams() {
    this.agreedWithOffer = false;

    const bonuses = this.bonusesAvailable;
    if (bonuses && bonuses >= this.minWithdrawalAmount)
      this.amountToWithdraw = bonuses;
    else
      this.amountToWithdraw = undefined;
  }

  async doWithdrawal() {
    if (this.doWithdrawalRunning)
      return;
    if (!this.canWithdraw)
      return;

    let func;
    if (this.withdrawalMethod === WithdrawalMethods.ACCOUNT)
      func = client.moveBonusesToBalance;
    else if (this.withdrawalMethod === WithdrawalMethods.CARD)
      func = client.moveBonusesToCard;
    else
      return;

    this.doWithdrawalRunning = true;

    try {
      const info = await func.call(client, this.amountToWithdraw);
      this.#fetchBonusesHistory();
      runInAction(() => {
        this.#parseBonuses(info);
      });
      popupStore.close();
    } catch (e) {
      if (e instanceof ClientError)
        messageBoxStore.showError(e.message);
      else
        throw e;
    } finally {
      runInAction(() => {
        this.doWithdrawalRunning = false;
      });
    }
  }

  setStartDate(date) {
    this.startDate = date;
  }

  setEndDate(date) {
    this.endDate = date;
  }
}

const referralStore = new ReferralStore();
export default referralStore;
