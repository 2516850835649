import { Link } from "react-router-dom";

import Icon from "components/UI/IconSVG/Icon";
import Badges from "components/UI/Badges/Badges";

import "./MarketItem.scss";

const MarketItem = ({
  market,
  index,
  onClick,
  linkTo,
  showBadgeStatus,
  containerClassName,
  itemClassName,
  logoClassName,
  titleClassName,
  showArrow = false,
  showBadge = false,
  isSelected = false,
}) => {

  const logoClass = isSelected
    ? `${logoClassName} ${logoClassName}-active`
    : `${logoClassName} ${logoClassName}-disabled`;

  const titleClass = isSelected
    ? `${titleClassName} ${titleClassName}-active`
    : `${titleClassName}`;

  return (
    <Link key={index} className={containerClassName} to={linkTo} onClick={onClick}>
      <div className={itemClassName}>
        <div className={`${itemClassName}-container`}>
          {isSelected && <div className="selected-line" />}
          <Icon className={logoClass} name={market.icon} />
          <p className={titleClass}>{market.titlePlaces}</p>
        </div>
        {showArrow && <div className={`${itemClassName}-arrow`} />}
      </div>
      {showBadge && <Badges status={showBadgeStatus} />}
    </Link>
  );
};

export default MarketItem;
