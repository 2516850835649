import Icon from "components/UI/IconSVG/Icon";

import "./FooterPlaceLogos.scss";

const placesData = [
  { img: "visa" },
  { img: "mir" },
  { img: "podeli" },
  { img: "ymoney" },
  { img: "otp" },
  { img: "yapay" },
  { img: "mts" },
  { img: "mir2" },
  { img: "halva" },
  { img: "hc" },
  { img: "sbp" },
  { img: "master" },
  { img: "union" },
];

export default function FooterPlaceLogos() {
  return (
    <div className="footer-place-logo">
      <div className="footer-place-logo__container">
        <p className="footer-place-logo__title">В BOOSTCLICK доступна</p>
        <p className="footer-place-logo__text">Безопасная оплата онлайн</p>
        <ul>
          <li>официально</li>
          <li>с чеком</li>
          <li>быстро</li>
        </ul>
        <div className="footer-place-logo__check" />
      </div>

      <div className="footer-place-logo__icon">
        <div className="footer-place-logo__icon-first"><Icon className="footer-place-logo__icon-first-logo" sprite="logo" name="robokassa" /></div>
        {placesData.map((item, index) => <Icon className="footer-place-logo__icon-logo" key={index} sprite="logo" name={item.img} />)}
      </div>
    </div>
  );
}
