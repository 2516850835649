import { observer } from "mobx-react";

import PropertyRow from "pages/Referral/components/UI/PropertyRow/PropertyRow";
import EmptySection from "pages/Referral/components/UI/EmptySection/EmptySection";
import ValueRow from "pages/Referral/components/UI/ValueRow/ValueRow";

import AppStore from "AppStore";

import "./ReferralHistorySection.scss";

export const ReferralHistorySection = observer(({
  data,
  isMobile,
  type,
  emptyTitle,
  emptyDescription,
  propertyRowConfig = {},
}) => {

  const defaultPropertyRowConfig = {
    userText: "Пользователь",
    dateText: "Дата регистрации",
    amountText: "Сумма",
    ...(type === "withdrawing" && { dateText: "Дата и время", statusText: "Статус" }),
  };

  const finalPropertyRowConfig = {
    ...defaultPropertyRowConfig,
    ...propertyRowConfig,
  };

  return (
    <>
      {!isMobile && <PropertyRow {...finalPropertyRowConfig} type={type} />}
      {data && (
        !data.length ? (
          <EmptySection title={emptyTitle} text={emptyDescription} />
        ) : (
          data.map((item, index) =>
            <ValueRow
              key={index}
              {...item}
              isMobile={isMobile}
              type={type}
              login={item.self ? AppStore.userEmail : item.emailHidden}
              amount={item.amount}
              date={item.datetime}
              status={item.status ?? ''}
            />
          )
        )
      )}
    </>
  );
});
