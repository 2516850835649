import Intro from "components/Intro/Intro";
import IntroIllustration from "components/Intro/IntroIllustration/IntroIllustration";
import IntroPoints from "components/Intro/IntroPoints/IntroPoints";

import "./IntroWithPoints.scss";

export default function IntroWithPoints({
  title,
  comment,
  points,
  marketPath,
  hiddenContent,
  isMobile,
}) {
  const classesReviewAvito = marketPath.includes('reviews-na-obyavleniya');
  const classesReviewYandex = marketPath.includes('reviews-on-yandexmaps');

  return (
    <section className="intro-with-points">
      {isMobile && <>{classesReviewAvito || classesReviewYandex ? <div className={`intro-markets__container-review ${classesReviewAvito ? 'avito' : 'yandex'}`}/> : <IntroIllustration marketPath={marketPath} />} </>}
      <Intro title={title} comment={comment} marketPath={marketPath} isMobile={isMobile} hiddenContent={hiddenContent}>
        <div className="intro-with-points__info-container">
          <div className="intro-with-points__comment">{comment}</div>
          {isMobile ? <IntroPoints stepLength={points} /> : <ul className="intro-with-points__list">{points?.map((point, i) => <li key={i}>{point}</li>)}</ul>}  
        </div>
      </Intro>
    </section>
  );
}
