import { observer } from "mobx-react";

import { ActivitiesCalculator } from "../componentsCalculatorStep/ActivitiesCalculator/ActivitiesCalculator";
import ModeSelection from "../componentsCalculatorStep/ModeSelection/ModeSelection";
import ExecutorSelection from "../componentsCalculatorStep/ExecutorSelection/ExecutorSelection";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider";
import Deadline from "../componentsCalculatorStep/Deadline/Deadline";
import Recommendation from "../componentsCalculatorStep/Recommendation/Recommendation";
import Faqed from "components/UI/Faqed/Faqed";
import { Notice } from "../componentsCalculatorStep/Notice/Notice";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Icon from "components/UI/IconSVG/Icon";

import { getCountActions, getCountDays } from "utils/helpers";

import upDoubleArrow from 'assets/images/upDoubleArrow.svg';

import "./IsDesktopCalculatorStep.scss";

export const IsDesktopCalculatorStep = observer(
  ({
    store,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
    showRecommendation,
  }) => {
    const { market, mode } = calculator;
    const notice = mode.notice;

    return (
      <div className="market-step2">
        <div className="market-step2__distribution">
          {store.calculators.length > 1 && <ModeSelection store={store} />}
          <Faqed text={mode.stepTwoTitleFaq}>
            <Icon className="market-step2-mobile__distribution-icon" name={market.icon} />
            <p className="market-step2__distribution-title">{mode.calculatorTitle}</p>
          </Faqed>
          <ActivitiesCalculator
            store={store}
            reviewRef={reviewRef}
            commentRef={commentRef}
          />
        </div>

        <div className="market-step2__settings">  
          {market.id === "yandexseo" && <ExecutorSelection store={store} />}
          <div className="market-step2__settings-container">
            <div className="market-step2__budget-slider-container">
              <p className="market-step2__budget-slider-total-cost">Общая стоимость{!showRecommendation ? "*" : ""}</p>
              <div className="market-step2__budget-slider-action">
                <BudgetSlider store={store}>
                  <p className="market-step2__project-price-info">
                    {`${store.currentCalculator.price} ₽`}
                  </p>
                  <div className="market-step2__project-info">
                    <div className="market-step2__project-info_action">
                      <p>≈</p>
                      <Icon className="market-step2__project-info_action-icon" sprite="activities" name="action" color="#4F4F4F" />
                      <p>{`${getAllActionCount(store.currentCalculator, pathname)} ${getCountActions(getAllActionCount(store.currentCalculator, pathname))}`}</p>
                    </div>
                    {getDisplayCount(store.currentCalculator, pathname) && (
                      <div className={`market-step2__project-info_reviews ${mode.actions.find((action) => action.type.includes("REVIEW")) ? "" : "hidden"}`}>
                        <p>≈</p>
                        <Icon className="market-step2__project-info_reviews-icon" sprite="activities" name="message" color="#4F4F4F" />
                        <p>{getDisplayCount(store.currentCalculator, pathname)}</p>
                      </div>
                    )}
                  </div>
                </BudgetSlider>
              </div>
              <div className="market-step2__deadline">
                <p className="market-step2__deadline-title">
                  Выберите скорость отправки заданий исполнителям:
                </p>
                <div className="market-step2__deadline-choice">
                  {store.currentCalculator.deadlines.map((deadline, i) => (
                    <Deadline
                      key={i}
                      deadline={`${deadline.days} ${getCountDays(
                        deadline.days
                      )}`}
                      emoji={deadline.emoji}
                      title={deadline.title}
                      text={deadline.text}
                      isActive={i === store.selectedDeadlineIndex}
                      onClick={() => {
                        store.selectDeadline(i);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            {showRecommendation && <Recommendation market={market} />}
            <ButtonWithIcon
              className="market-step2__create-button"
              title="Следующий шаг"
              icon={upDoubleArrow}
              onClick={onClickNext}
            />
          </div>

          {!showRecommendation && <Notice notice={notice} />}
        </div>
      </div>
    );
  }
);
