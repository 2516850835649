import Icon from 'components/UI/IconSVG/Icon';

import './FooterButton.scss';

export function FooterButton({ to, title, icon, onClick }) {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      <button className={'footer-button' + (icon ? ' footer-button_iconed' : '')} onClick={onClick}>
        {icon && <Icon className='footer-button__icon' sprite="logo" name={icon} />}
        {title}
      </button>
    </a>
  );
}
