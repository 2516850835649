import { Fragment } from "react";

import Plate from "components/UI/Plate/Plate";
import Icon from "components/UI/IconSVG/Icon";

import "./HowItWorksSteps.scss";

export const HowItWorksSteps = ({ spriteName, icon, step, title, text, isReferralPage, isThirdStep }) => {
  return (
    <Plate className={`how-it-works-steps ${isReferralPage ? "other-params" : ""} ${isThirdStep ? "third-step" : ""}`}>
      <div className="how-it-works-steps__icons">
        <Icon className="how-it-works-steps__icons-icon" sprite={spriteName} name={icon} />
        <Icon className="how-it-works-steps__icons-step" sprite={spriteName} name={step} />
      </div>
      <hr className={`how-it-works-steps__icons-border ${isReferralPage ? "other-params" : "" }`} />

      <div className="how-it-works-steps__description">
        <p className="how-it-works-steps__description-title">{title}</p>
        <p className="how-it-works-steps__description-text">
          {Array.isArray(text) 
            ? text.map((item, i) => <Fragment key={i}>{item}</Fragment>)
            : text
          }
        </p>
      </div>
    </Plate>
  );
};

