import { observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import ReferralInformation from "./components/ReferralInformation/ReferralInformation";
import { HowItWorksSteps } from "components/HowItWorks/HowItWorksSteps/HowItWorksSteps";
import ReferralLink from "./components/ReferralLink/ReferralLink";
import Withdrawing from "./Withdrawing/Withdrawing";
import Invited from "./Invited/Invited";
import Answers from "components/Answers/Answers";

import referralStore from "./ReferralStore";
import AppStore from "AppStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import { promoData, REFERRAL_DISCLAIMER, REFERRAL_FAQ } from "./referralConstants";

import "./Referral.scss";

export const Referral = observer(({ sponsorId }) => {
  const { pathname } = useLocation()
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const isReferralPage = pathname === "/referral";

  const isAuth = AppStore.isAuth;

  useEffect(() => {
    if (isAuth)
      referralStore.fetchData();
  }, [isAuth, isMobile, isTablet, isDesktop]);

  return (
    <main className="referral">
      <Helmet>
        <title>Реферальная программа — Boostclick</title>
        <meta name="description" content="Рекомендуйте наши услуги друзьям и знакомым и получайте вознаграждения за каждого привлеченного клиента. Участвовать просто: делитесь уникальной ссылкой, следите за прогрессом в личном кабинете и наслаждайтесь бонусами за свою активность. Начните зарабатывать уже сегодня!" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        {sponsorId && <meta name="robots" content="noindex" />}
      </Helmet>

      <div className="referral__inner">
        <div className="referral__information-container">
          <ReferralInformation />
          <div className="referral__information-steps">
            {promoData.map((step, index) => <HowItWorksSteps key={index} {...step} isReferralPage={isReferralPage} isThirdStep={index === 2} />)}
          </div>
        </div>
        <ReferralLink store={referralStore} />
        <Withdrawing store={referralStore} />
        <Invited store={referralStore} />
        {isMobile && <p className="referral__disclaimer">{REFERRAL_DISCLAIMER}</p>}
        <Answers title={REFERRAL_FAQ.title} questions={REFERRAL_FAQ} />
      </div>

    </main>
  );
});
