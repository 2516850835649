import { Link } from "react-router-dom";

import Icon from "../IconSVG/Icon";

import "./InfoPoint.scss";

const InfoPoint = ({ icon, spriteIcon, children, text, span, link, to, target="_blank", rel="noopener noreferrer", onClick }) => (
  <div className="info-point">
    {spriteIcon 
      ? <Icon className="info-point__icon" sprite={spriteIcon.sprite} name={spriteIcon.name} />
      : <div className="info-point__icon" style={{ background: `url(${icon}) center/contain no-repeat` }} />
    }
    {children 
      ? <div>{children}</div>
      : <div className="info-point__text" onClick={onClick}>
          {text}
          {span && <span className="info-point__span">{span}</span>}
          {link && <Link to={to} target={target} rel={rel} className="info-point__link">{link}</Link>}
        </div>
    }
  </div>
);

export default InfoPoint;
