import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Icon from "components/UI/IconSVG/Icon";

import { MARKET_GROUPS } from "AppStore";

import "./SiteIcons.scss";

const FORMAT_ROW = "row";
const FORMAT_GRID = "grid";
const FORMAT_COLUMN = "column";

export const SiteIcons = observer(
  ({ storeDropDown, storeApp, isMobile, onClick }) => {
    const getFormatClassName = (baseClassName, currentFormat) => {
      return `${baseClassName}${currentFormat !== FORMAT_ROW ? `-${currentFormat}` : ""}`;
    };

    const formatClassNames = {
      link: getFormatClassName('dropdown-links__logos-link', storeDropDown.displayFormat),
      item: getFormatClassName('dropdown-links__logos-item', storeDropDown.displayFormat),
      title: getFormatClassName('dropdown-links__logos-title', storeDropDown.displayFormat),
    };

    const changeFormat = (format) => {
      storeDropDown.setDisplayFormat(format);
    };

    const renderMarketLink = (marketStore) => (
      <Link
        key={marketStore.market.id}
        to={marketStore.currentCalculator.mode.path}
        onClick={(link) => {
          if (onClick) onClick(link);
          storeApp.selectMarketStoreAndCalculatorByPath(marketStore.currentCalculator.mode.path);
          window.scrollTo(0, 0);
        }}
        className={formatClassNames.link}
      >
        <Icon className={formatClassNames.item} name={marketStore.market.icon} width={28} height={28} />
        <p className={formatClassNames.title}>{marketStore.market.titlePlaces}</p>
      </Link>
    );

    const renderMarketplaces = () => {
      const filteredMarketStores = storeApp.filteredMarkets;
      const isSearching = filteredMarketStores.length < storeApp.marketStores.length;

      if (filteredMarketStores.length === 0) return <div className="dropdown-links__logos_result-search">Ничего не найдено</div>;

      if (isMobile && storeDropDown.displayFormat === FORMAT_ROW) {
        return filteredMarketStores.map((marketStore, index) => renderMarketLink(marketStore, index));
      }

      return Object.entries(MARKET_GROUPS)
        .map(([groupName, marketIds]) => {
          const groupMarkets = marketIds.map((marketId) => filteredMarketStores.find((store) => store.market.id === marketId)).filter(Boolean);

          if (groupMarkets.length === 0) return null;

          return (
            <div key={groupName} className={`dropdown-links__market-group_format-${storeDropDown.displayFormat}`}>
              {(isMobile || storeDropDown.displayFormat === FORMAT_COLUMN) && !isSearching && (
                <p className="dropdown-links__market-group_title">
                  {groupName}
                  <span />
                </p>
              )}
              {groupMarkets.map((marketStore, index) => renderMarketLink(marketStore, index))}
            </div>
          );
        })
        .filter(Boolean);
    };

    return (
      <>
        {storeApp.marketStores.length > 1 && (
          <div className="dropdown-links__logos">
            <div className="dropdown-links__logos_header">
              <p className="dropdown-links__logos_header-title">Платформы</p>
              <div className="dropdown-links__logos_header-buttons">
                {isMobile && (
                  <button
                    className={`dropdown-links__logos_header-button-${FORMAT_ROW} ${
                      storeDropDown.displayFormat === FORMAT_ROW ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Поменять представление иконок"
                    onClick={() => changeFormat(FORMAT_ROW)}
                  />
                )}
                {[FORMAT_GRID, FORMAT_COLUMN].map((format) => (
                  <button
                    key={format}
                    className={`dropdown-links__logos_header-button-${format} ${
                      storeDropDown.displayFormat === format ? "active" : ""
                    }`}
                    type="button"
                    aria-label="Поменять представление иконок"
                    onClick={() => changeFormat(format)}
                  />
                ))}
              </div>
            </div>
            <nav className={`dropdown-links__logos-items format-${storeDropDown.displayFormat}`}>{renderMarketplaces()}</nav>
          </div>
        )}
      </>
    );
  }
);
