import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";

import Tooltips from "components/UI/Tooltips/Tooltips";
import { Hint } from "components/UI/Hint/Hint";
import Icon from "components/UI/IconSVG/Icon";

import {
  TOOLTIP_NO_WARRANTY,
  TITLE_WARRANTY_INCLUDED,
  DESCRIPTION_WARRANTY_INCLUDED,
  TITLE_WARRANTY_EXPIRED,
  DESCRIPTION_WARRANTY_EXPIRED,
  TITLE_WARRANTY_VALID,
  DESCRIPTION_WARRANTY_VALID_PART1,
  DESCRIPTION_WARRANTY_VALID_PART2,
} from "./projectPropertyColumnReviewsConstants";

import { getHoursFormatTime } from "../../../Projects/ProjectsConstants";

import "./ProjectPropertyColumnReviews.scss";

const getWarrantyStyle = (reportTimer, status, isCancelling) => {
  let remainingTime = Math.max(reportTimer.timeLeft, 0);

  if (isCancelling) {
    return (
      <Tooltips description="Вы отменили задание">
        <div className="project-property-column-reviews__warranty no-warranty">
          <div className="project-property-column-reviews__warranty-icon no-warranty" />
          <p className="project-property-column-reviews__warranty-text no-warranty">
            отмен.
          </p>
        </div>
      </Tooltips>
    );
  }

  if (["new", "run"].includes(status)) {
    return (
      <div className="project-property-column-reviews__warranty more">
        <div className="project-property-column-reviews__warranty-icon more" />
        <p className="project-property-column-reviews__warranty-text more">
          вкл.
        </p>
      </div>
    );
  }

  let containerStyle, iconStyle, textStyle;
  if (remainingTime === 0) {
    containerStyle = iconStyle = textStyle = "end";
  } else if (remainingTime <= 24 * 60 * 60 * 1000) {
    containerStyle = iconStyle = textStyle = "one-day";
  } else if (remainingTime <= 48 * 60 * 60 * 1000) {
    containerStyle = iconStyle = textStyle = "two-days";
  } else {
    containerStyle = iconStyle = textStyle = "more";
  }

  return (
    <div className={`project-property-column-reviews__warranty ${containerStyle}`}>
      <div className={`project-property-column-reviews__warranty-icon ${iconStyle}`} />
      <p className={`project-property-column-reviews__warranty-text ${textStyle}`} >
        {getHoursFormatTime(remainingTime)}
      </p>
    </div>
  );
};

export const ProjectPropertyColumnReviews = observer(
  ({ project, reportTimer, title, text, className, activityIcon, status }) => {
    const modalRef = useRef();
    const requestRef = useRef();

    const [showModal, setShowModal] = useState(false);
    const [showExtrasReview, setShowExtrasReview] = useState(false);

    const activities = project?.activities || [];
    const reviews = activities.find((activity) => activity.type === "MAIN.REVIEW");
    const warranty = reviews?.warranty;
    const isCancelling = project.reports?.some(report => report.cancelling);

    let extrasReview = reviews ? ((project.extras?.comment || project.extras) ?? "") : "";
    if (typeof extrasReview !== "string")
      extrasReview = "";

    const renderHint = (reportTimer, isCancelling) => {
      let title = "";
      let description = "";

      let remainingTime = Math.max(reportTimer.timeLeft, 0);

      if (["new", "run"].includes(status)) {
        title = TITLE_WARRANTY_INCLUDED;
        description = DESCRIPTION_WARRANTY_INCLUDED;
      } else if (remainingTime === 0) {
        title = TITLE_WARRANTY_EXPIRED;
        description = DESCRIPTION_WARRANTY_EXPIRED;
      } else if (isCancelling) {
        return;
      } else {
        title = TITLE_WARRANTY_VALID;
        description = [
          DESCRIPTION_WARRANTY_VALID_PART1,
          <br />,
          <br />,
          `До конца гарантии осталось ${getHoursFormatTime(remainingTime)}`,
          <br />,
          <br />,
          DESCRIPTION_WARRANTY_VALID_PART2,
        ];
      }

      return (
        <div ref={modalRef}>
          <Hint
            className="hint-project-property-column-reviews"
            title={title}
            text={description}
          />
        </div>
      );
    };

    useEffect(() => {
      function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setShowModal(false);
        }
        if (requestRef.current && !requestRef.current.contains(event.target)) {
          setShowExtrasReview(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [modalRef]);

    return (
      <div className={`project-property-column-reviews ${status}`}>
        <div className="project-property-column-reviews__title">{title}</div>
        <div className={`project-property-column-reviews__text ${className}`}>
          {activityIcon && <Icon className="project-property-column__icon" sprite="activities" name={activityIcon} color='#FFE14B' />}
          <div onClick={() => setShowExtrasReview(true)}>
            {text}
            {showExtrasReview && 
              <div className="project-property-column-reviews__request" ref={requestRef}>
                <p className="project-property-column-reviews__request-title">
                  Ваш запрос к исполнителю:
                </p>
                <p className="project-property-column-reviews__request-text">
                  {extrasReview}
                </p>
              </div>
            }
          </div>

          {warranty ? (
            <>
              <div onMouseOver={() => {setShowModal(true)}} onMouseLeave={() => {setShowModal(false)}}>
                {getWarrantyStyle(reportTimer, status, isCancelling)}
              </div>
              {showModal && renderHint(reportTimer, isCancelling)}
            </>
          ) : (
            <Tooltips description={TOOLTIP_NO_WARRANTY} innerStyle={{ top: "-5px" }}>
              <div className="project-property-column-reviews__warranty no-warranty">
                <div className="project-property-column-reviews__warranty-icon no-warranty" />
                <p className="project-property-column-reviews__warranty-text no-warranty">
                  выкл.
                </p>
              </div>
            </Tooltips>
          )}
        </div>
      </div>
    );
  }
);
