import { observer } from "mobx-react";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Badges from "components/UI/Badges/Badges";

import selectedIcon from "assets/images/selectdeadline.svg";

import "./ExecutorSelection.scss";

const ExecutorSelection = observer(({ store }) => {
  const selectedExecutor = store.market.executorTypes.find((executor) => executor.id === store.executorType);

  const handleExecutorSelect = (executorType) => {
    store.setExecutorType(executorType);
  };

  return (
    <div className="executor-selection">
      <p className="executor-selection__title">Источник трафика</p>
      <div className="executor-selection__container">
        {store.market.executorTypes.map((executor) => (
          <div className="executor-selection__wrapper">
            <ButtonWithIcon
              key={executor.id}
              title={executor.title}
              className={`executor-selection__button ${store.executorType === executor.id ? "active" : ""}`}
              icon={store.executorType === executor.id ? selectedIcon : ""}
              onClick={() => handleExecutorSelect(executor.id)}
              ariaLabel={`Выбрать режим ${executor.title}`}
            />
            {executor.id === 'bots' && <Badges status="дешевле" />}
          </div>
        ))}
      </div>
      <p className="executor-selection__text">{selectedExecutor?.description}</p>
    </div>
  );
});

export default ExecutorSelection;
