import Plate from "components/UI/Plate/Plate";

import "./Banner.scss";

const Banner = ({
  image,
  content,
  imagePosition,
  className
}) => {
  return (
    <div className={`banner ${imagePosition === "right" ? "other-position" : ""} ${className}`}>
      <img src={image} alt="изображение Дино-помощника" className={`banner__image ${className}`} />
      <Plate className={`banner__container ${className}`}>{content}</Plate>
    </div>
  );
};

export default Banner;
