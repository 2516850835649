import {
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from "../market";

const MEGAMARKET_FAQ = [
  {
    title: "Как работает сервис Boostclick?",
    isH3: false,
    text:
      <p>
        Boostclick — это биржа заданий для продвижения товаров на маркетплейсах. 
        Вы выбираете действия по карточке товара (например, добавить в избранное, кликнуть по рекламе), а реальные люди выполняют эти действия, присылая вам подробный отчет.
      </p>
  },
  {
    title: "Как продвигать товары на Мегамаркет через Boostclick?",
    isH3: false,
    text:
      <p>
        <b>1. Разместите товары на Мегамаркет:</b>
        <br />
        Убедитесь, что ваши товары доступны для покупки на Мегамаркет.
        <br />
        <br />
        <b>2. Оптимизируйте карточки товаров и подберите конкурентоспособную цену:</b>
        <br />
        Уделите внимание качеству фотографий, описания, заголовка и характеристик товара.
        <br />
        Проанализируйте цены конкурентов и установите привлекательную стоимость для покупателей.
        <br />
        <br />
        <b>3. Создайте задание для исполнителей на бирже Boostclick:</b>
        <br />
        Опишите подробно, какие действия должны выполнить исполнители (например, добавить в избранное, перейти по ссылке, просмотреть видео).
        <br />
        Укажите количество действий и установите бюджет.
        <br />
        <br />
        <b>4. Подберите оптимальное количество действий по вашему аккаунту и карточке товаров:</b>
        <br />
        Ориентируйтесь на активность конкурентов.
        <br />
        Ваша активность по карточкам должна быть сопоставима или выше, чтобы выделиться на фоне конкурентов.
        <br />
        <br />
        <b>5. После выполнения заданий исполнители отправят вам отчет:</b>
        <br />
        Проверьте отчет, чтобы убедиться, что все действия выполнены  правильно.
        <br />
        <br />
        <b>6. Примите задание, если все действия выполнены верно:</b>
        <br />
        Если у вас есть сомнения, отправьте задание на доработку с указанием комментариев.
      </p>
  },
  {
    title: "Как работают поведенческие факторы для товаров в Мегамаркете?",
    isH3: false,
    text:
      <div>
        Поведенческие факторы играют важную роль в определении позиций товаров на Мегамаркет, так как они отражают реальный интерес покупателей к продукту.<br />
        Вот как они влияют на ранжирование:
        <br />
        <br />
        <b>1. CTR (Click-Through Rate) — процент кликов:</b>
        <br />
        <br />
        <ul>
          <li>Чем выше CTR, тем больше людей заинтересовались вашим товаром, перейдя на его страницу.</li><br />
          <li>Это говорит о том, что ваш товар привлекателен для покупателей, его описание и изображения качественные, а цена конкурентоспособна.</li><br />
          <li>Хороший CTR повышает ваш рейтинг и товар показывается выше в поисковой выдаче.</li>
        </ul>
        <br />
        <br />
        <b>2. CR (Conversion Rate) — процент добавлений в корзину:</b>
        <br />
        <br />
        <ul>
          <li>Чем выше CR, тем чаще покупатели решают добавить ваш товар в корзину после просмотра его страницы.</li><br />
          <li>Это указывает на то, что ваше предложение удовлетворяет потребности покупателей, а цена и условия доставки их устраивают.</li><br />
          <li>Высокий CR подтверждает качество вашего товара и приводит к его повышению в рейтинге.</li>
        </ul>
        <br />
        <br />
        <b>3. Время, проведенное на странице товара:</b>
        <br />
        <br />
        <ul>
          <li>Если покупатели долго просматривают ваш товар, это говорит о том, что он их интересует.</li><br />
          <li>Этот фактор показывает качество изображений, описания и цены товара.</li><br />
          <li>Чем дольше покупатели задерживаются на странице, тем выше шансы, что они купят товар.</li>
        </ul>
        <br />
        <br />
        <b>4. Количество просмотров:</b>
        <br />
        <br />
        <ul>
          <li>Чем больше людей просматривает ваш товар, тем выше его популярность.</li><br />
          <li>Этот фактор указывает на то, что ваш товар находится в зоне интересов покупателей, а его описание и изображения достаточно привлекательны, чтобы привлечь внимание.</li><br />
        </ul>
        <br />
        <br />
        <b>5. Количество добавлений в избранное:</b>
        <br />
        <br />
        <ul>
          <li>Когда покупатели добавляют ваш товар в избранное, это означает, что они хотят вернуться к нему позже.</li><br />
          <li>Этот фактор показывает, что товар удовлетворяет потребности покупателей, и они готовы его купить в будущем.</li>
        </ul>
        <br />
        <br />
        <b>6. Количество покупок:</b>
        <br />
        <br />
        <ul>
          <li>Это самый прямой показатель интереса к вашему товару.</li><br />
          <li>Чем больше людей покупают ваш товар, тем выше его позиция в рейтинге и тем больше шансов, что он будет показываться выше в поисковой выдаче.</li>
        </ul>
        <br />
        <br />
        <b>7. Отзывы:</b>
        <br />
        <br />
        <ul>
          <li>Положительные отзывы от покупателей увеличивают доверие к вашему товару и приводят к повышению его позиции.</li><br />
          <li>Ваши ответы на отзывы также влияют на рейтинг товара и отношение покупателей.</li>
        </ul>
        <br />
        <br />
        <b>Важно:</b>
        <br />
        <br />
        <ul>
          <li>Мегамаркет использует сложный алгоритм ранжирования, который учитывает множество факторов, включая поведенческие.</li><br />
          <li>Не все факторы имеют одинаковый вес в алгоритме.</li><br />
          <li>Поведенческие факторы работают в комплексе с другими критериями, такими как качество товара, цена, доставка и отзывы.</li><br />
        </ul>
      </div>
  },
  {
    title: "Когда ждать результат?",
    isH3: false,
    text:
      <p>
        Это зависит от множества факторов, например, от того, насколько верно вы оценили конкуренцию и насколько точно подобрали объем действий по карточке и аккаунту. 
        Важно, насколько правильно сделана оптимизация вашего объявления.<br />
        Если все сделано правильно, то результат будет примерно через 2—5 дней.
      </p>
  },
  {
    title: "Какие гарантии?",
    isH3: false,
    text:
      <p>
        Сервис Boostclick гарантирует, что все задания будут выполнены реальными людьми и в полном объеме.<br />
        В случае сомнений в качестве отчета, заказчик вправе отправить его на доработку в указанное в проекте время.<br />
        Все услуги оказываются согласно размещенному на сайте договору-оферте.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = (
  <p>
    Почему при добавлении одного действия в калькуляторе появляются
    дополнительные действия?
    <br />
    <br />
    Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
    сценарий начинается для нового исполнителя с поиска товара по сделанному
    скриншоту вашей ссылки.
  </p>
);

const REVIEW_PRICE = 250;

const ACTIONS = [
  {
    type: "MAIN.FAVORITE",
    icon: "heart",
    iconHome: "home-heart",
    title: "Добавить товар в избранное",
    initialShare: 0.5,
    activityType: 'Избранное',
  },
  {
    type: "MAIN.BUSKET",
    icon: "busket",
    iconHome: "home-busket",
    title: "Добавить товар в корзину",
    initialShare: 0.2,
    activityType: 'Корзина',
  },
  {
    type: "MAIN.LIKE",
    icon: "like",
    iconHome: "home-like",
    title: "Поставить лайк на последний отзыв",
    initialShare: 0.2,
    activityType: 'Лайк',
  },

  {
    type: "ALWAYS.OPEN",
    icon: "open",
    iconHome: "home-open",
    title: "Открыть сайт или приложение Мегамаркет",
    initialShare: 1,
  },
  {
    type: "ALWAYS.SEARCH",
    icon: "search",
    iconHome: "home-search",
    title: "Найти товар через поиск Мегамаркет",
    initialShare: 1,
  },
  {
    type: 'ALWAYS.COMPETITORS',
    icon: "time",
    iconHome: "home-time",
    title: 'Быстро посмотреть конкурентов',
    initialShare: 1,
  },
  {
    type: "ALWAYS.CLICK",
    icon: "click",
    iconHome: "home-click",
    title: "Зайти на карточку товара",
    initialShare: 1,
  },

  {
    type: "EXTRA.MEDIA",
    icon: "eye",
    iconHome: "home-eye",
    title: "Посмотреть все фото и видео на карточке",
    initialShare: 0.5,
  },
  {
    type: "EXTRA.DESCRIPTION",
    icon: "glasses",
    iconHome: "home-glasses",
    title: "Прочитать полное описание товара",
    initialShare: 0.2,
  },
  {
    type: "EXTRA.SPECS",
    icon: "glasses",
    iconHome: "home-glasses",
    title: "Нажать «Все характеристики»",
    initialShare: 0.1,
  },
  {
    type: "EXTRA.GOODS",
    icon: "click",
    iconHome: "home-click",
    title: "Посмотреть другие товары продавца",
    initialShare: 0.1,
  },
  {
    type: "EXTRA.FEEDBACKS",
    icon: "glasses",
    iconHome: "home-glasses",
    title: "Прочитать отзывы на товар",
    initialShare: 0.1,
  },

  {
    type: "MAIN.THUMBSUP",
    icon: "like",
    iconHome: "home-like",
    title: "Поставить палец вверх на последний вопрос о товаре",
    initialShare: 0.1,
    activityType: 'Лайк',
  },
  {
    type: "MAIN.DISLIKE",
    icon: "dislike",
    iconHome: "home-dislike",
    title: "Поставить дизлайк на последний отзыв",
    initialShare: 0,
    activityType: 'Дизлайк',
  },
];

const STEP_PHRASE_2 = "Укажите информацию для поиска товара";

const MEGAMARKET_KEYWORDS = [
  'Mega', 'Megamarket', 'mega', 'megamarket', 'Мега', 'Мегамаркет', 'мегамаркет', 'мега'
];

class MegamarketClass extends Market {
  id = "megamarket";
  host = "megamarket.ru";
  url = "https://megamarket.ru/";

  title = "Мегамаркет";

  isMarketplace = true;

  icon = "megamarket";
  titlePlaces = "Мегамаркет";
  statusForHomePage = MARKET_MODE_STATUS.NEW;

  categories = [
    { type: "Супермаркет" },
    { type: "Товары для дома" },
    { type: "Красота и уход" },
    { type: "Детские товары" },
    { type: "Электроника" },
    { type: "Бытовая техника" },
    { type: "Книги, хобби, канцелярия" },
    { type: "Одежда, обувь и аксессуары" },
    { type: "Продукты питания" },
    { type: "Здоровье" },
    { type: "Автотовары" },
    { type: "Зоотовары" },
    { type: "Мебель" },
    { type: "Строительство и ремонт" },
    { type: "Спорт и активный отдых" },
    { type: "Уборка и гигиена" },
    { type: "Дача, сезонные товары" },
    { type: "Ювелирные изделия" },
    { type: "Услуги и цифровые товары" },
    { type: "Товары для геймеров" },
    { type: "Автомобили и мототехника" },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = 24;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = MEGAMARKET_KEYWORDS;

  targetLinkComment = "Укажите тут адрес вашего товара на Мегамаркете";
  targetLinkCommentShort = "Укажите ссылку товара";

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: "/povedencheskie-faktory-na-megamarket",
      rating: 0.68,
      title: "ПФ",
      navigateTitle: "Улучшение ПФ на Мегамаркете",
      icon: "lightning",
      iconForHomePage: "home-action",
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title:
          "Накрутка поведенческих факторов (ПФ) Мегамаркет от 1.9 руб — купить просмотры, отзывы, лайки | Boostclick",
        description: "Улучшайте ПФ на Мегамаркете с помощью сервиса Boostclick. С нами вы можете заказать поведенческий фактор.",
      },
      intro: {
        title: "Продвижение на Мегамаркете",
        comment: 'Благодаря индивидуальным поведенческим сценариям пользователей',
        points: [
          "Без ботов",
          "Без самовыкупов",
          "Только уникальные пользователи",
          "Соблюдение сроков",
          "Подробные отчеты",
          "Поиск вашего магазина на Мегамаркете по ключевым запросам",
        ],
      },
      howItWorks: [
        "Настройте параметры активности в соответствии с конкуренцией",
        "Укажите ссылку на товар для скриншота",
        "Укажите категорию, город и ключевые фразы из карточки товара",
        "После оплаты мы передадим исполнителю скриншот и параметры для поиска",
        "Проверьте ответы и активность, примите работу или отправьте на доработку",
      ],
      stepPhrase1: "Настройте параметры поведенческого фактора (ПФ) Мегамаркета",
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: "Продвижение на Мегамаркете",
      titleFAQ: "Вопрос-ответ о накрутке Мегамаркет",
      faq: MEGAMARKET_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(
            (action) =>
              action.type !== "MAIN.REVIEW" && !action.type.startsWith("VIEW.")
          ),
          ...ACTIONS.filter((action) => action.type === "MAIN.REVIEW"),
        ];
      })(),
      notice: [
        "* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.",
        "Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на странице вашего товара.",
        "В первую очередь будут выполняться действия связанные с органическим поиском и изучением товаров, далее добавление в избранное и корзину.",
        "Сложность цепочек и время исполнения зависит от их количества в проекте.",
      ],
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action;
    return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на товар';
  invalidUrlStr = 'Укажите корректную ссылку на товар';
}

const Megamarket = new MegamarketClass();
export default Megamarket;
