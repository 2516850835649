export const REFERRAL_TITLE = "Партнерская программа";
export const REFERRAL_DISCLAIMER = <>10% - ПФ и просмотры; 5% - отзывы; 10% - подписчики ВК, Телеграм.<br /><br /> <strong>Важно:</strong> Бонусы начисляются только за принятые задания и в зависимости от суммы пополнения. При отказе от работы исполнителя или возврате средств рефералом бонусы за не принятые задания не начисляются. <br /><br />Реферальная программа доступна только для юридических лиц и самозанятых. Физические лица в программе не принимают участие.</>;
export const REFERRAL_LINK_SUBTITLE = "Чтобы пригласить пользователя, отправьте ссылку или разместите ее на своем сайте, блоге или странице в социальных сетях.";

export const REFERRAL_WITHDRAWING_EMPTY_TITLE = "Тут пусто";
export const REFERRAL_WITHDRAWING_EMPTY_DESCRIPTION = "Истории операций нет, пока никто из приглашенных пользователей не принял задание";
export const REFERRAL_WITHDRAWAL_CONDITIONS_TITLE = "Условия вывода средств";

export const REFERRAL_INVITED_EMPTY_TITLE = `Пока никого нет :(`;
export const REFERRAL_INVITED_EMPTY_DESCRIPTION = "Не опускайте руки! Вы делаете все правильно, и вскоре ваши друзья откликнутся. Главное напоминайте им о вашей ссылке 😉";

export const promoData = [
  {
    spriteName: "another",
    icon: "icon-step-first-referral",
    step: "number-step-first",
    title: "Копируйте",
    text: "Скопируйте реферальную ссылку платформы Boostclick",
  },
  {
    spriteName: "another",
    icon: "icon-step-second-referral",
    step: "number-step-second",
    title: "Отправляйте",
    text: "Поделитесь ссылкой на своем сайте, блоге или странице в соц. сетях",
  },
  {
    spriteName: "another",
    icon: "icon-step-third-referral",
    step: "number-step-third",
    title: "Зарабатывайте",
    text: "Зарабатывайте и выводите деньги за регистрацию пользователей",
  },
];

export const REFERRAL_FAQ = [
  {
    title: 'Где искать клиентов?',
    isH3: false,
    text:
      <>
        Выбор источников привлечения клиентов зависит от ваших ресурсов и опыта.
        <br />
        Вот несколько популярных вариантов:
        <br />
        <br />
        <ul>
          <li>Рекомендации: Предложите услуги биржи знакомым, которым они могут быть полезны.</li>
          <br />
          <li>Тематический трафик: Создайте лендинг, группу в соцсетях или другой тематический ресурс и привлекайте на него целевую аудиторию, перенаправляя ее на биржу по вашей реферальной ссылке.</li>
          <br />
          <li>Видеообзоры: Снимите короткие видеоролики для YouTube, рассказывающие о партнерской программе, преимуществах биржи и способах заработка с помощью вашей реферальной ссылки.</li>
          <br />
          <li>Публикации на тематических площадках: Напишите обзоры и статьи о бирже и разместите их на популярных платформах, таких как VC, Habr, Т-Журнал и других.</li>
        </ul>
      </>
  },
  {
    title: 'Как долго я буду получать % от клиентов?',
    isH3: false,
    text:
      <p>
        Вы будете получать партнерские начисления в течение двух лет с момента регистрации реферала за средства, которые он потратил на бирже.
      </p>
  },
  {
    title: 'Мой реферал пополнил баланс, почему я не получил начисления?',
    isH3: false,
    text:
      <p>
        Начисления по партнерской программе происходят только после того, как ваш реферал примет (оплатит) задание исполнителям. 
        <br />
        Если реферал пополнил баланс, но пока не воспользовался услугами биржи, ваши партнерские проценты будут начислены после того, как он оплатит выполнение задания.
      </p>
  },
  {
    title: 'Как фиксируется переход по реферальной ссылке?',
    isH3: false,
    text:
      <p>
        Данные о переходе записываются в Cookies браузера и сохраняются там 30 дней. 
        <br />
        То есть, если пользователь вернется на сайт в течение месяца (не важно, по какой ссылке он зайдет), то он все равно будет закреплен за вами.
      </p>
  },
];
