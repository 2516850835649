import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import { IsDesktopSettingsStep } from "./IsDesktopSettingsStep/IsDesktopSettingsStep";
import { IsMobileSettingsStep } from "./IsMobileSettingsStep/IsMobileSettingsStep";
import BannerStep from "components/BannerStep/BannerStep";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { setCookie } from "utils/cookie";

import clipIcon from "assets/images/clip.svg";

import "./SettingsStep.scss";

const DESCRIPTION_FAQ_SCREENSHOT =
  "Наши исполнители ищут объявление органически, не переходя напрямую по ссылке — таким образом мы поднимаем ПФ и не вызываем подозрения у площадок. Обычно мы переводим ссылки в скриншоты автоматически, но на некоторых платформах это невозможно. Тогда нам нужна ваша помощь :)";
const DESCRIPTION_FAQ_CATEGORY =
  "Укажите категорию, в которой находится ваше объявление, так его будет легче найти.";
const DESCRIPTION_FAQ_CITY =
  "На одно объявление можно выбрать один город. Город должен совпадать с городом, указанным в объявлении.";

export const SettingsStep = observer(
  ({
    store,
    linkRef,
    categoryRef,
    cityRef,
    searchPhrasesRef,
    screenshotRef,
    scrollToError,
    reviewRef,
    commentRef,
  }) => {
    const { isMobile } = useMatchMedia();
    const navigate = useNavigate();
    const needFullParams = store.needFullParams;
    const currentTitlePlaces =
      AppStore.currentMarketStore.calculators[0].market.title;

    function onChangeLinkToAd(event) {
      store.setLinkToAd(event.target.value);
    }

    function onChangeAddress(event) {
      store.setAddress(event.value);
      store.setCountry(event.data.country);
      store.setCity(event.data.city);
    }

    async function onClickCreateProject() {
      if (!AppStore.isAuth) {
        const redirectUrl = window.location.pathname;
        setCookie("redirect_url", redirectUrl, { expires: 1 });
        return navigate("/signin");
      }

      let res = true;
      if (!store.validateStep1()) {
        const errorRefs = [
          store.linkError ? linkRef : null,
          store.categoryError ? categoryRef : null,
          store.cityError ? cityRef : null,
          store.searchPhrasesError ? searchPhrasesRef : null,
          AppStore.uploadedFileError ? screenshotRef : null,
        ];
        const firstErrorRef = errorRefs.find((ref) => ref !== null);
        if (firstErrorRef) {
          scrollToError(firstErrorRef);
        }
        return;
      }

      if (!store.validateStep2()) {
        if (reviewRef) scrollToError(reviewRef);
        else if (commentRef) scrollToError(commentRef);
        res = false;
      }
      if (!res) return;

      store.setIsCreatingProject(true);
      popupStore.setAgreedRulesRaisePrice(false);
      popupStore.open("accept-rules");
      AppStore.setActiveTab("drafts");
    }

    return (
      <>
      <Plate className="market-step1-plate">
        {isMobile ? (
          <IsMobileSettingsStep
            store={store}
            needFullParams={needFullParams}
            nameSite={currentTitlePlaces}
            linkRef={linkRef}
            categoryRef={categoryRef}
            cityRef={cityRef}
            searchPhrasesRef={searchPhrasesRef}
            screenshotRef={screenshotRef}
            scrollToError={scrollToError}
            reviewRef={reviewRef}
            commentRef={commentRef}
            onChangeLinkToAd={onChangeLinkToAd}
            onChangeAddress={onChangeAddress}
            descriptionScreenshot={DESCRIPTION_FAQ_SCREENSHOT}
            descriptionCategory={DESCRIPTION_FAQ_CATEGORY}
            descriptionCity={DESCRIPTION_FAQ_CITY}
            iconUploadFile={clipIcon}
          />
        ) : (
          <IsDesktopSettingsStep
            store={store}
            needFullParams={needFullParams}
            nameSite={currentTitlePlaces}
            linkRef={linkRef}
            categoryRef={categoryRef}
            cityRef={cityRef}
            searchPhrasesRef={searchPhrasesRef}
            screenshotRef={screenshotRef}
            scrollToError={scrollToError}
            reviewRef={reviewRef}
            commentRef={commentRef}
            onChangeLinkToAd={onChangeLinkToAd}
            onChangeAddress={onChangeAddress}
            descriptionScreenshot={DESCRIPTION_FAQ_SCREENSHOT}
            descriptionCategory={DESCRIPTION_FAQ_CATEGORY}
            descriptionCity={DESCRIPTION_FAQ_CITY}
            iconUploadFile={clipIcon}
          />
        )}
      </Plate>

      <BannerStep banner="banner-third-step" onClick={onClickCreateProject} isMobile={isMobile} />
      </>
    );
  }
);
