import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import BannerStep from "components/BannerStep/BannerStep";
import Plate from "components/UI/Plate/Plate";
import SettingsSeoYandex from "./components/SettingsSeoYandex/SettingsSeoYandex";
import SettingsSeoOther from "./components/SettingsSeoOther/SettingsSeoOther";
import TextBlock from "components/UI/TextBlock/TextBlock";
import UploadFile from "components/UploadFile/UploadFile";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import { setCookie } from "utils/cookie";
import { useMatchMedia } from "utils/hooks/use-match-media";

import clipIcon from "assets/images/clip.svg";

import "./SettingsSeoStep.scss";

export const SettingsSeoStep = observer(({
  store,
  linkRef,
  cityRef,
  searchPhrasesRef,
  screenshotRef,
  brandPhrasesRef,
  scrollToError,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  async function onClickCreateProject() {
    if (!AppStore.isAuth) {
      const redirectUrl = window.location.pathname;
      setCookie("redirect_url", redirectUrl, { expires: 1 });
      return navigate("/signin");
    }

    let res = true;
    if (!store.validateStepSeo()) {
      const errorRefs = [
        store.linkError ? linkRef : null,
        store.cityError ? cityRef : null,
        store.searchPhrasesError ? searchPhrasesRef : null,
        store.brandPhrasesError ? brandPhrasesRef : null,
        AppStore.uploadedFileError ? screenshotRef : null,
      ];
      const firstErrorRef = errorRefs.find((ref) => ref !== null);
      if (firstErrorRef) {
        scrollToError(firstErrorRef);
      }
      return;
    }
    if (!res) return;

    store.setIsCreatingProject(true);
    popupStore.setAgreedRulesRaisePrice(false);
    popupStore.open("accept-rules");
    AppStore.setActiveTab("drafts");
  }

  return (
    <>
      <Plate className="settings-seo-step">
        <Plate className="settings-seo-step__container">
          {store.pfYandex ?
            <SettingsSeoYandex
              store={store}
              cityRef={cityRef}
              searchPhrasesRef={searchPhrasesRef}
              brandPhrasesRef={brandPhrasesRef}
              screenshotRef={screenshotRef}
            />
          :
            <SettingsSeoOther
              store={store}
              linkRef={linkRef}
              screenshotRef={screenshotRef}
            />
          }
        </Plate>

        {!isMobile &&
          <div className="settings-seo-step__last">
            <div className="settings-seo-step__title">
              <TextBlock
                title={store.market.isMaps ? "Прикрепите скриншот карточки организации" : `Пришлите скриншот заголовка (title) вашего сайта в ${store.market.title}, чтобы исполнитель понимал куда перейти`}
                titleClassName="settings-seo-step__label settings-seo-last"
              />
              <TextBlock
                title={store.market.isMaps ? "" : "Важно: Выделите заголовок или удалите конкурентов"}
                titleClassName="settings-seo-step__sublabel"
              />
            </div>
            <UploadFile
              className="settings-seo-step__add-screen-button"
              fileType="landingScreenshot"
              title={store.market.isMaps ? "Прикрепить скриншот карточки организации" : `Прикрепить скриншот из ${store.market.title} (без конкурентов)`}
              icon={clipIcon}
            />
          </div>
        }
      </Plate>
      {!isMobile && <BannerStep banner="banner-third-step" onClick={onClickCreateProject} isMobile={isMobile} />}

      {isMobile &&
        <>
          <div className="settings-seo-step__last">
            <div className="settings-seo-step__title">
              <TextBlock
                title={store.market.isMaps ? "Прикрепите скриншот карточки организации" : `Прикрепить скриншот из ${store.market.title} (без конкурентов)`}
                titleClassName="settings-seo-step__label settings-seo-last"
              />
            </div>
            <div className="settings-seo-step__buttons">
              <p>Добавьте скриншот в .JPG или .PNG или .WEBP</p>
              <UploadFile
                className="settings-seo-step__add-screen-button"
                fileType="landingScreenshot"
                title="Прикрепить скриншот"
                icon={clipIcon}
              />
            </div>
          </div>
          <BannerStep banner="banner-third-step" onClick={onClickCreateProject} isMobile={isMobile} />
        </>
      }
    </>
  );
});
