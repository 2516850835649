import validator from 'validator';

export const MARKET_MODES = {
  CHAINS: 'chains',
  LIKES: 'likes',
  FOLLOWERS: 'followers',
  VIEWS: 'views',
  COMMENTS: 'comments',
  REVIEWS: 'reviews',
};

export const MARKET_MODE_STATUS = {
  REGULAR: 'regular',
  NEW: 'new',
  BETA: 'beta',
  EXCLUSIVE: 'exclusive',

  // for home page
  POPULAR: 'popular',
  SOON: 'soon',
  HIT: 'hit',
};

/**
 * Типы действий:
 * - ALWAYS: действие всегда привязывается к цепочке
 * - MAIN: основное действие в данном режиме, участвует в основнм распределении
 * - EXTRA: дополнительное действие в данном режиме, участвует в дополнительном распределении
 */
export const ACTION_TYPES = {
  ALWAYS: 'always',
  MAIN: 'main',
  EXTRA: 'extra',
};

/**
 * @typedef Action
 * @property {string} type Идентификатор действия. Должен быть заменен на `id`.
 * @property {string} trueType Тип действия из `ACTION_TYPES`.
 * Один и тот же идентификатор в разных режимах может иметь разные типы.
 */

/**
 * @typedef MarketMode
 * @property {string} id Идентификатор маркета из `MARKET_MODES`.
 * @property {Action[]} actions Действия в этом режиме.
 */

export class Market {

  /**
   * @param {string} url
   * @returns {string|null} Сообщение об ошибке.
   */
  validateTargetUrl(url) {
    if (!url)
      return this.emptyUrlStr ?? 'Укажите ссылку на продвигаемый ресурс';

    if (!validator.isURL(url))
      return this.invalidUrlStr ?? 'Укажите корректную ссылку на продвигаемый ресурс';

    if (this.isSeo || this.isMaps)
      return null;

    let hostsList = this.hostsList ?? [];
    if (this.host)
      hostsList.push(this.host);
    if (hostsList.length) {
      if (!hostsList.some(host => url.includes(host)))
        return this.invalidUrlStr ?? 'Укажите корректную ссылку на продвигаемый ресурс';
    }

    return null;
  }
}
