import Icon from 'components/UI/IconSVG/Icon';

import './ProjectPropertyColumn.scss';

export default function ProjectPropertyColumn({ title, text, className, activityIcon, status, color, timer }) {
  return (
    <div className={`project-property-column ${status}`}>
      <div className='project-property-column__title'>{title}</div>
      <div className={`project-property-column__text ${className}`} style={{ color }}>
        {activityIcon && <Icon className="project-property-column__icon" sprite="activities" name={activityIcon} color='#FFE14B' />}
        {text}
      </div>
      {timer && 
        <div className="project-property-column__timer">
          <div className="project-property-column__timer-icon" />
          <p>{timer}</p>
        </div>
      }
    </div>
  );
}
